<template>
    <div class="kt-portlet" v-if="steps.length > 0 && loading === false">
        <div class="kt-portlet__body kt-portlet__body--fit">
            <div class="kt-grid  kt-wizard-v2 kt-wizard-v2--white" id="kt_wizard_v2" data-ktwizard-state="step-first">
                <div class="kt-grid__item kt-wizard-v2__aside">

                    <!--                    navigation headers-->

                    <div class="kt-wizard-v2__nav">
                        <div class="kt-wizard-v2__nav-items kt-wizard-v2__nav-items--clickable">
                            <div @click="index = i" class="kt-wizard-v2__nav-item" data-ktwizard-type="step"
                                 v-for="(step, i) in steps" :key="step.id"
                                 :data-ktwizard-state="i === index ? 'current': 'pending'">
                                <div class="kt-wizard-v2__nav-body">
                                    <div class="kt-wizard-v2__nav-icon">
                                        <i :class="step.icono"></i>
                                    </div>
                                    <div class="kt-wizard-v2__nav-label">
                                        <div class="kt-wizard-v2__nav-label-title">
                                            {{step.nombre}}
                                        </div>
                                        <div class="kt-wizard-v2__nav-label-desc">
                                            {{step.descripcion}}
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>

                <!--                    content-sections-->
                <div class="kt-grid__item kt-grid__item--fluid kt-wizard-v2__wrapper">
                    <form class="kt-form" id="kt_form">

                        <div class="kt-wizard-v2__content" data-ktwizard-type="step-content" v-for="(step, i) in steps"
                             :key="step.id"
                             :data-ktwizard-state="i === index? 'current': undefined">

                            <div v-if="step.campos">
                                <div class="kt-heading kt-heading--md">
                                    {{step.descripcion}}
                                </div>
                                <div class="kt-form__section kt-form__section--first">
                                    <div class="kt-wizard-v2__form">
                                        <vue-form-generator ref="forms"
                                                            @model-updated=updated
                                                            v-if="step.data"
                                                            :schema="step.data.schema"
                                                            :model="step.data.model"
                                                            :option="formOptions"></vue-form-generator>
                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                <div class="kt-heading kt-heading--md">
                                    Revisar datos y guardar
                                </div>
                                <div class="alert alert-primary" role="alert">
                                  Revise detenidamente la información ingresada. Si tiene algo que corregir hágalo este momento.
                                </div>
                                <div class="kt-form__section kt-form__section--first">
                                    <div class="kt-wizard-v2__review">
                                        <div class="kt-wizard-v2__review-item" v-for="item in steps" :key="item.id"
                                             v-show="item.campos">
                                            <div class="kt-wizard-v2__review-title">
                                                {{item.nombre}}
                                            </div>
                                            <div class="kt-wizard-v2__review-content" v-if="item.data">
                                                <p v-for="(elem, key) in formatData( {...item.data.schema}, {...item.data.model})"
                                                   :key="key + item.id"><strong>{{
                                                    item.data.schema.fields.find(x => x.model === key).label}}:</strong>
                                                    {{ elem }}</p>
                                                <br>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <!--begin: Form Actions -->
                        <div class="kt-form__actions">
                            <button class="btn btn-secondary btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u"
                                    data-ktwizard-type="action-prev" @click="index--">
                                Anterior
                            </button>
                            <button v-on:click="submit"
                                    class="btn btn-success btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u"
                                    data-ktwizard-type="action-submit">
                                Confirmar y generar contrato
                            </button>
                            <button class="btn btn-brand btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u"
                                    data-ktwizard-type="action-next" @click="goNext()">
                                Siguiente
                            </button>
                        </div>


                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import VueFormGenerator from "vue-form-generator";
    import datePicker from 'vue-bootstrap-datetimepicker';


    import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
    import KTUtil from "@/assets/js/util";
    import KTWizard from "@/assets/js/wizard";
    import Swal from "sweetalert2";
    import service from "@/service/contract.service"
    import router from "../../../router";
    import {ADD_BREADCRUMB} from "../../../store/breadcrumbs.module";

    const COMPLETEINFO = "COMPLETA";
    const PAYREQ = "Incompleto";
    const monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

    export default {
        name: "TransactionRegister",
        components: {
            "vue-form-generator": VueFormGenerator.component,
            // eslint-disable-next-line vue/no-unused-components
            datePicker
        },
        data() {
            return {
                toPay: false,
                steps: [],
                allSteps: [],
                wizard: null,
                index: 0,
                loading: false,
                formOptions: {
                    validationErrorClass: "has-error",
                    validationSuccessClass: "has-success",
                    validateAfterChanged: true
                },
                changeHistory: []
            }
        },

        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: "Documento"}]);
            if (this.$route.params.id) {
                service.get(this.$route.params.id).then(result => {
                    const contract = result.data.data;
                    this.showInitialMessage(contract);
                    this.$store.dispatch(ADD_BREADCRUMB, [{title: contract.nombre}]);
                });
                service.getFirstStep(this.$route.params.id).then(r => {
                    const resp = r.data.data[0];
                    const schema = JSON.parse(resp.campos);
                    const item = resp;
                    this.setValidations(schema);
                    item.data = schema;
                    this.steps.push(item);
                    this.steps.push({
                        id: -1,
                        nombre: "Resumen",
                        descripcion: " Revisar y enviar",
                        icono: "flaticon-confetti"
                    });
                    setTimeout(() => {
                        this.renderWizard(1);
                    }, 1);
                })
            }

        },
        methods: {
            updated(value, field) {
                if (this.changeHistory.find(x => {
                    return x.field === field
                })) {
                    return;
                }
                this.changeHistory.push({
                    field,
                    value
                });
            },
            getData() {
                let result = {};

                const data = this.steps.filter(x => x.id !== -1).map(x => {
                    let params = x;
                    const fields = {...params.data.schema};
                    const values = {...params.data.model};
                    return {...this.formatData(fields, values)};
                });
                data.forEach(x => {
                    result = {...result, ...x}
                });
                result = {
                    contrato_id: this.$route.params.id,
                    datos: JSON.stringify(result)
                };
                return result;

            },
            renderWizard(index) {
                this.index = index - 1;
                const wizard = new KTWizard("kt_wizard_v2", {
                    startStep: index,
                    clickableSteps: true
                });
                this.wizard = wizard;
                setTimeout(() => {
                    let elements = document.getElementsByClassName("glyphicon");
                    for (let i = 0; i < elements.length ; i++) {
                        elements[i].classList.add("fa");
                        elements[i].classList.add("fa-calendar");
                        elements[i].classList.add("spanClass");
                    }
                },1000);

                wizard.on("beforeNext", function (/*wizardObj*/) {
                });


                wizard.on("change", function (/*wizardObj*/) {
                    setTimeout(function () {
                        KTUtil.scrollTop();
                    }, 500);
                });
            },
            setValidations(data) {
                data.schema.fields.forEach(x => {
                    x.validator = VueFormGenerator.validators.required;
                })
            },

            evalChanges(index) {
                if (this.steps[index].data && this.steps[index - 1].flow !== "-1") {
                    const flow = JSON.parse(this.steps[index - 1].flujo);
                    if (flow.value) {
                        return this.changeHistory.find(x => {
                            return x.field === flow.field
                        });
                    } else {
                        return false;
                    }
                }
                return true;
            },
            loadNextWizardStep(index) {
                const indexTo = index + 1;
                this.changeHistory = [];
                this.loading = false;
                setTimeout(() => {
                    this.renderWizard(indexTo);
                }, 1);
            },
            goNext() {
                this.wizard.stop();
                const index = this.wizard.currentStep;
                if (this.$refs.forms[index - 1]) {
                    if (!this.$refs.forms[index - 1].validate())
                        return
                }
                this.loading = true;
                if (this.evalChanges(index)) {
                    let params = this.steps[index - 1];
                    params = {
                        id: params.id,
                        data: params.data.model
                    };
                    service.getNexStep(params).then(x => {
                        const resp = x.data.data;
                        if (resp && resp.id) {
                            const schema = JSON.parse(resp.campos);
                            const item = resp;
                            this.setValidations(schema);
                            item.data = schema;
                            if (this.steps[index].id !== -1) {
                                this.steps[index] = item;
                            } else {
                                this.steps.splice(this.steps.length - 1, 0, item);
                            }
                        }
                    }).catch(()=> {
                        if (this.steps[index].id !== -1) {
                            this.steps.splice(index, 1);
                        }
                    }).finally(() => {
                        this.loadNextWizardStep(index);
                    });
                } else {
                    setTimeout(() => {
                        this.loadNextWizardStep(index);
                    }, 10)
                }


            },
            formatData(fields, data) {
                fields = typeof (fields) === 'object' ? Object.values(fields)[0] : fields;
                fields.forEach(form => {
                    if (form.type === 'dateTimePicker') {
                        const value = new Date(data[form.model]);
                        data[form.model] = value instanceof Date && !isNaN(value) ? this.formatDate(new Date(data[form.model])) : data[form.model];
                    }
                });
                return data;
            },
            formatDate(date) {
                return ('0' + date.getDate()).slice(-2) + ' de ' + monthNames[date.getMonth()] + ' del ' + date.getFullYear();
            },
            navigate(route) {
                router.push(route)
            },
            dialogCompleteInformation(result) {
                Swal.fire({
                    title: "Información del usuario incompleta",
                    text: "Por favor complete su información para terminar de realizar la transacción",
                    icon: "warning",
                    preConfirm: () => {
                        this.navigate({
                            name: 'profile', params: {
                                service: 'PAGO DE CONTRATO',
                                detail: result
                            }
                        })
                    }
                });
            },
            dialogPaymentRequired(result) {
                Swal.fire({
                    title: "¡Información registrada correctamente!",
                    text: "Para poder visualizar su documento es necesario realizar el pago, tiene las opciones:",
                    icon: "success",
                    showCloseButton: false,
                    showCancelButton: true,
                    confirmButtonText: '<i class="fa  fa-credit-card"></i> Pagar este contrato',
                    confirmButtonAriaLabel: '',
                    cancelButtonText: '<i class="fa fa-address-card"></i> Contratar un plan &nbsp;&nbsp;',
                    cancelButtonAriaLabel: '',
                    preConfirm: () => {
                        this.toPay = true;
                        this.$router.push({
                            name: 'preview', params: {
                                service: 'PAGO DE CONTRATO',
                                detail: result
                            }
                        })
                    },
                    onClose: () => {
                        if (!this.toPay) {
                            this.navigate('/plan');
                        }
                    },
                    allowOutsideClick: () => !Swal.isLoading()

                })
            },
            dialogProcessComplete(result) {
                Swal.fire({
                    title: "¡Documento generado exitosamente!",
                    text: "Por lanzamiento de nuestra plataforma la generación de documentos es gratuita. Comentarios o sugerencias escríbenos a info@adjudica.ai",
                    // text: "Felicitaciones, su documento ha sido generado exitosamente.",
                    icon: "success",
                    showCloseButton: false,
                    showCancelButton: false,
                    confirmButtonText: '<i class="fa fa-print"></i> ¡Ver contrato!',
                    onClose: () => {
                        this.$router.push('/transaction')
                    },
                    preConfirm: () => {
                       window.open(result.producto.contrato.path,'_blank')
                    },
                })
            },

            submit: function (e) {
                e.preventDefault();
                const payload = this.getData();
                service.registerTransaction(payload).then((x) => {
                    const result = x.data.data;
                    if (result.infousuario === COMPLETEINFO) {
                        if (result.estado === PAYREQ) {
                            this.dialogPaymentRequired(result);
                        } else {
                            this.dialogProcessComplete(result);
                        }
                    } else {
                        this.dialogCompleteInformation(result)
                    }
                }).catch(() => {
                    Swal.fire({
                        title: "",
                        text: "Un error a ocurrido",
                        icon: "error",
                        confirmButtonClass: "btn btn-secondary",
                        preConfirm: () => {
                            this.$router.push('/transaction')
                        }
                    });
                });

            },
            showInitialMessage(item) {
                Swal.fire({
                    title: "Bienvenido al formulario de " + item.nombre,
                    text: item.descripcion,
                    icon: "success",
                    showCloseButton: false,
                    showCancelButton: false,
                    confirmButtonText: '<i class="fa fa-check"></i> ¡Empezemos!',
                })
            }
        },
        computed: {
        }

    }
</script>

<style scoped lang="scss">
    @import "@/assets/sass/pages/wizard/wizard-2.scss";
    /*@import "//netdna.bootstrapcdn.com/bootstrap/3.0.0/css/bootstrap.min.css";*/

    /*@import "https://cdnjs.cloudflare.com/ajax/libs/evil-icons/x.y.z/evil-icons.min.css";*/
    div ::v-deep .input-group {
        flex-wrap: inherit !important;
    }

    div ::v-deep .input-group.date .input-group-addon {
        cursor: pointer;
        padding: 12px 12px;
        font-size: 14px;
        font-weight: normal;
        line-height: 1;
        text-align: center;
        background-color: #eee !important;
        border: 0.2px solid #ccc !important;
    }

    /*<link href="//netdna.bootstrapcdn.com/bootstrap/3.0.0/css/bootstrap.min.css" rel="stylesheet">*/

</style>
